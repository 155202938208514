import React, { useState } from "react";
import "./App.css";
import Title from "./title/title";

export default function App() {
  const [mouseLocationState, setMouseLocation] = useState({ x: 0, y: 0 });
  const onMouseMoveHandler = (event) => {
    setMouseLocation({
      x: event.pageX,
      y: event.pageY,
    });
  };
  return (
    <div onMouseMove={onMouseMoveHandler} className="App">
      <Title mouseLocation={mouseLocationState} />
    </div>
  );
}
