import { useEffect, useRef } from "react";
import useWindowDimensions from "../hooks/useDimensions";
import "./title.css";
import React from "react";

function Title({ mouseLocation }) {
  const titleTextRef = useRef();
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (titleTextRef.current) {
      const scaledX =
        (mouseLocation.x / width) * titleTextRef.current.clientWidth;
      const scaledY =
        (mouseLocation.y / height) * titleTextRef.current.clientHeight;
      console.log(scaledX, scaledY);
      titleTextRef.current.style.backgroundImage = `radial-gradient(circle at ${scaledX}px ${scaledY}px, #102830, #3a647d, #bc0f50 110%)`;
    }
  }, [mouseLocation]);

  return (
    <div className="title-container">
      <h1 ref={titleTextRef} className="title">
        Vishal Saini
      </h1>
    </div>
  );
}

export default Title;
